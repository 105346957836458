import React, { useState } from 'react';
import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaw,
    faFileSignature,
    faImagesUser,
    faHeartPulse,
    faCalendarDays,
    faCreditCard
} from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faPhoneFlip } from '@fortawesome/pro-regular-svg-icons';
import Client from '@spike/client-model';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useMarketplace } from 'hooks';
import { Button } from 'components/UI';
import { ActionsPopup } from 'components/UI/ActionsPopup/ActionsPopup';
import { UnderlinedButton } from 'components/UI/UnderlinedButton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { AddPetKey, AgreementsKey, ClientKey, HistoryKey, PetsKey, PrefixPetKey, CardsKey } from 'pages/Client';
import { RootState } from 'store';
import Pet from '@spike/pet-model';
import { useSelector } from 'react-redux';
import MobileButtonActions from './MobileButtonActions';
import Tag from 'components/UI/V2/Tag/Tag';

type ClientEditHeaderProps = {
    client: Client;
    onBook: () => void;
    onClose: () => void;
    onSelect: (key: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: 'solid 1px #D4D4D4',
            paddingBottom: 24,
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        clientInfo: {
            'gap': 10,
            'maxWidth': '50%',
            'display': 'flex',
            'alignItems': 'flex-start',

            '& h3': {
                fontSize: 20,
                lineHeight: 1.2,
                fontWeight: 600,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            },
            '& p': {
                'fontSize': 15,
                'lineHeight': 1,

                '& svg': {
                    fontSize: 18,
                    marginRight: 6,
                    verticalAlign: 'middle'
                }
            }
        },
        clientInfoMeta: {
            gap: 8,
            display: 'flex',
            flexDirection: 'column'
        },
        back: {
            all: 'unset',
            fontSize: 20,
            lineHeight: 1,
            padding: '2px 0px'
        },
        clientActions: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        bookButton: {
            'height': 34,
            'padding': '0px 24px',

            '& span': {
                fontSize: 14
            }
        },
        actionsDropdownButton: {
            all: 'unset',
            width: 35,
            height: 35,
            borderRadius: '50%',
            textAlign: 'center',
            backgroundColor: '#F1F1F1'
        }
    })
);

export const ClientEditHeader: React.FunctionComponent<ClientEditHeaderProps> = props => {
    const classes = useStyles();

    const marketplace = useMarketplace();

    const pets = useSelector<RootState, Array<Pet>>(state => state.pets.list);

    const [showActions, setShowActions] = useState(false);

    return (
        <>
            <div className={classes.root}>
                <div className={classes.clientInfo}>
                    <button
                        type="button"
                        aria-label="Go back"
                        className={classes.back}
                        onClick={props.onClose}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>

                    <div className={classes.clientInfoMeta}>
                        <Typography variant="h3">
                            {props.client.firstName} {props.client.lastName}
                        </Typography>

                        <Typography>
                            <FontAwesomeIcon icon={faPhoneFlip} />
                            {formatPhoneNumber(props.client.phone, String(marketplace.basics.address.country?.id))}
                        </Typography>

                        {props.client.blocked && (
                            <div>
                                <Tag variant="danger">Blocked</Tag>
                            </div>
                        )}
                    </div>
                </div>

                <div className={classes.clientActions}>
                    {props.client.pets.length > 0 && !props.client.blocked && (
                        <Button
                            color="green"
                            label="Book"
                            className={classes.bookButton}
                            onClick={props.onBook}
                        />
                    )}
                    <MobileButtonActions onClikedActions={() => setShowActions(true)} />
                </div>
            </div>

            {showActions && (
                <ActionsPopup
                    actions={[
                        {
                            label: 'Client Information',
                            icon: faImagesUser,
                            onClick: () => props.onSelect(ClientKey)
                        },
                        {
                            icon: faPaw,
                            label: 'Pets',
                            items: pets.map(pet => ({
                                label: (
                                    <>
                                        {pet.name}
                                        {pet.deceased ? ' 🌈' : ''}
                                        {pet.hasMedicalConditions && (
                                            <FontAwesomeIcon
                                                size="lg"
                                                color="#EF4F57"
                                                icon={faHeartPulse}
                                                style={{ marginLeft: 4 }}
                                            />
                                        )}
                                    </>
                                ),
                                onClick: () => props.onSelect(`${PrefixPetKey}${pet.id}`)
                            })),
                            rightElement: (
                                <UnderlinedButton
                                    color="green"
                                    label="Add Pet"
                                    leftIcon={<FontAwesomeIcon icon={faPlus} />}
                                    onClick={() => props.onSelect(AddPetKey)}
                                />
                            ),
                            onClick: () => props.onSelect(PetsKey)
                        },
                        {
                            label: 'Cards',
                            icon: faCreditCard,
                            onClick: () => props.onSelect(CardsKey)
                        },
                        {
                            label: 'History',
                            icon: faCalendarDays,
                            onClick: () => props.onSelect(HistoryKey)
                        },
                        {
                            label: 'Agreements',
                            icon: faFileSignature,
                            onClick: () => props.onSelect(AgreementsKey)
                        }
                    ]}
                    onClose={() => setShowActions(false)}
                />
            )}
        </>
    );
};
