import React, { FunctionComponent, useState, useEffect } from 'react';
import { Box, Typography, createStyles, makeStyles, Theme, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, ConfirmDialog, Spinner } from 'components/UI';
import Header from './Header';
import { CardGroup } from 'components/ClientCards/Card/CardGroup';
import AddCreditCard from './Card/AddCreditCard';
import { showError, showSuccess } from '@spike/notifications-action';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientPaymentMethodThunk, getClientPaymentMethodsThunk, PaymentsStatus } from '@spike/payments-action';
import { useApiClientWrapper } from 'hooks';
import { RootState } from 'store';
import { ClientPaymentMethod } from '@spike/payments-model';
import useNonInitialEffect from '@versiondos/hooks';
import { ClientEditHeader } from 'components/Client/ClientEditHeader';
import Client from '@spike/client-model';

interface ClientCardsProps {
    clientId: number;
    client: Client;
    onBook: () => void;
    onClose: () => void;
    onSelect: (key: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minHeight: '100%',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        cardContainer: {
            padding: '10px 0',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '-15px',
                paddingTop: '0px'
            }
        },
        cardInfoWrapper: {
            width: '100%',
            display: 'flex',
            gap: '50px',
            padding: '30px',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '-72px',
                padding: '30px 20px'
            }
        },
        cardInfo: {
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        cardList: {
            width: '70%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            }
        },
        title: {
            color: '#222',
            fontSize: 18,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: '24px'
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '25px'
            }
        },
        subTitle: {
            color: '#000',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.4,
            marginTop: 10,

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        cardGroup: {
            backgroundColor: '#fff'
        },
        emptyCartGroup: {
            width: '100%',
            borderRadius: 18,
            [theme.breakpoints.up('sm')]: {
                padding: '6px 32px',
                border: 'solid 1px #DDDDDD',
                backgroundColor: '#fff',
                minHeight: '80px',
                display: 'flex',
                alignItems: 'center'
            }
        },
        emptyCartText: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1,
            color: '#000'
        },
        errorContainer: {
            width: '100%',
            padding: '10px 30px'
        },
        dialogActions: {
            'width': '100%',
            'display': 'flex',

            '& button': {
                'flex': 1,
                'height': '50px',
                '& + button': {
                    marginLeft: 10
                }
            }
        },
        loadingContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        editWrapper: {
            padding: '24px 16px 0px 16px'
        }
    })
);

const ClientCards: FunctionComponent<ClientCardsProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const [loading, setLoading] = useState(true);
    const [showAddCard, setShowAddCard] = useState<boolean>(false);
    const [showDeleteCard, setShowDeleteCard] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const [error, setError] = useState<string | boolean>(false);
    const [cardSelectedToDelete, setCardSelectedToDelete] = useState<ClientPaymentMethod | null>(null);
    const cards = useSelector<RootState, ClientPaymentMethod[]>(state => state.payments.clientPaymentMethods);

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(state => state.payments.status);

    useEffect(() => {
        dispatch(getClientPaymentMethodsThunk(apiClientWrapper, props.clientId));
    }, [props.clientId]);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.GettingClientPaymentMethods:
                setLoading(true);
                break;
            case PaymentsStatus.GetClientPaymentMethodsSuccess:
                setLoading(false);
                break;
            case PaymentsStatus.DeleteClientPaymentMethodSuccess:
                dispatch(getClientPaymentMethodsThunk(apiClientWrapper, props.clientId));
                setLoading(false);
                break;
        }
    }, [paymentsStatus]);

    const toggleAddCardHandler = () => {
        setShowAddCard(!showAddCard);
    };

    const deleteCardHandler = (card: ClientPaymentMethod) => {
        dispatch(deleteClientPaymentMethodThunk(apiClientWrapper, props.clientId, card.id));
        setShowDeleteCard(false);
        setCardSelectedToDelete(null);
    };

    const completeHandler = () => {
        dispatch(showSuccess('Credit card added successfully!'));
        setLoading(true);
        setShowAddCard(false);
        setTimeout(() => {
            dispatch(getClientPaymentMethodsThunk(apiClientWrapper, props.clientId));
        }, 4000);
    };

    const errorHandler = (error: string | undefined) => {
        dispatch(showError('Error adding credit card!'));
        setError(true);
    };

    const setShowDeleteCardHandler = (card: ClientPaymentMethod) => {
        setShowDeleteCard(true);
        setCardSelectedToDelete(card);
    };

    return (
        <>
            <Box className={classes.root}>
                {loading && (
                    <Box className={classes.loadingContainer}>
                        <Spinner />
                    </Box>
                )}

                {!loading && (
                    <>
                        {isMobile && (
                            <Box className={classes.editWrapper}>
                                <ClientEditHeader
                                    client={props.client}
                                    onSelect={props.onSelect}
                                    onBook={props.onBook}
                                    onClose={props.onClose}
                                />
                            </Box>
                        )}
                        <Box className={classes.cardContainer}>
                            <Header onAdd={toggleAddCardHandler} />

                            <Box className={classes.cardInfoWrapper}>
                                <Box className={classes.cardInfo}>
                                    <Typography
                                        variant="h2"
                                        className={classes.title}
                                    >
                                        Cards Details
                                    </Typography>
                                    <Typography className={classes.subTitle}>
                                        Cards on file are securely stored for future payments, making transactions
                                        quicker and easier for your clients.
                                    </Typography>
                                </Box>
                                <Box className={classes.cardList}>
                                    {cards.length > 0 && (
                                        <CardGroup
                                            cards={cards}
                                            onError={errorHandler}
                                            hideDefaultBadge={true}
                                            onDeleteCard={setShowDeleteCardHandler}
                                            hideEditButton={true}
                                            className={classes.cardGroup}
                                            clientId={props.clientId}
                                            showAddCard={showAddCard}
                                            onCloseAddCard={toggleAddCardHandler}
                                        />
                                    )}
                                    {cards.length === 0 && (
                                        <Box className={classes.emptyCartGroup}>
                                            <Typography className={classes.emptyCartText}>
                                                No credit card on file.
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
                {showAddCard && (
                    <AddCreditCard
                        clientId={props.clientId}
                        open={showAddCard}
                        onClose={() => setShowAddCard(false)}
                        onComplete={completeHandler}
                        onError={errorHandler}
                    />
                )}

                {showDeleteCard && cardSelectedToDelete && (
                    <ConfirmDialog
                        open={showDeleteCard}
                        title={<Typography>Delete Card</Typography>}
                        onCancel={() => setShowDeleteCard(false)}
                        question={
                            <Typography
                                style={{
                                    maxWidth: 270,
                                    margin: '0px auto'
                                }}
                            >
                                Are you sure you want to delete the card •••• {cardSelectedToDelete.lastDigits}?
                            </Typography>
                        }
                        actions={
                            <Box className={classes.dialogActions}>
                                <Button
                                    label="No"
                                    color="black"
                                    disabled={loading}
                                    id="clients_dialog_block_client_cancel_button"
                                    onClick={() => setShowDeleteCard(false)}
                                />

                                <Button
                                    label="Yes"
                                    color="green"
                                    loading={loading}
                                    variant={'danger'}
                                    id="clients_dialog_block_client_confirm_button"
                                    onClick={() => deleteCardHandler(cardSelectedToDelete)}
                                />
                            </Box>
                        }
                    />
                )}
            </Box>
        </>
    );
};

export default ClientCards;
