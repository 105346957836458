import { faHeartPulse } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, PetAvatar, PetTypeChip } from 'components/UI';
import Client from '@spike/client-model';
import { FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useCommonStyles } from './CommonStyles';

interface PetRowsProps {
    client: Client;
    className?: string;
    onEdit?: (clientId: number, petId: number) => void;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        petRow: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(18)}px`,
                paddingBottom: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '18px',
                paddingBottom: '18px'
            }
        },
        petRowTopBorder: {
            borderTop: '1px solid #DDDDDD'
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: 'black',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px'
            }
        },
        avatar: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(8)}px`,
                width: `${reduceResolution(40)}px`,
                height: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                width: '40px',
                height: '40px'
            }
        },
        heartIcon: {
            color: '#EF4F57',
            fontWeight: 300,
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(19)}px`,
                marginLeft: `${reduceResolution(7)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '19px',
                marginLeft: '7px'
            }
        }
    })
);

const PetRows: FunctionComponent<PetRowsProps> = props => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();

    return (
        <>
            {props.client.pets.map((pet, index) => (
                <Grid
                    container
                    item
                    xs={12}
                    key={`${pet.id}`}
                    className={clsx(classes.petRow, { [classes.petRowTopBorder]: index > 0 })}
                >
                    <Grid
                        item
                        xs={3}
                        className={commonClasses.cellVerticalCenter}
                    >
                        <PetAvatar
                            className={classes.avatar}
                            petType={pet.type}
                            imageUrl={pet.imageUrl}
                        />
                        <Typography className={classes.text}>
                            {pet.name}
                            {pet.deceased ? ' 🌈' : ''}
                        </Typography>
                        {pet.hasMedicalConditions && (
                            <FontAwesomeIcon
                                icon={faHeartPulse}
                                className={classes.heartIcon}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={clsx(commonClasses.cellVerticalCenter, commonClasses.cellCenter)}
                    >
                        <Typography className={classes.text}>
                            <PetTypeChip petType={pet.type} />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        className={commonClasses.cellVerticalCenter}
                    >
                        <Typography className={classes.text}>{pet.breed?.name || 'Undefined'}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={commonClasses.cellVerticalCenter}
                    >
                        <Typography className={classes.text}>{pet.gender?.id ? pet.gender?.name : '-'}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={commonClasses.cellVerticalCenter}
                    >
                        <Typography className={classes.text}>{pet.age?.id ? pet.age?.name : '-'}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        className={clsx(commonClasses.cellVerticalCenter)}
                    >
                        <Typography className={classes.text}>{pet.hasMedicalConditions ? 'Yes' : 'No'}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={clsx(commonClasses.cellVerticalCenter, commonClasses.cellCenter)}
                    >
                        {props.onEdit && (
                            <Link
                                label="View pet"
                                color="black"
                                underline={true}
                                onClick={() => props.onEdit && props.onEdit(props.client.id!, pet.id!)}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    ></Grid>
                </Grid>
            ))}
        </>
    );
};

export default PetRows;
