import React, { FunctionComponent, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import { faClock, faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import StaffChip from 'components/UI/StaffChip';
import clsx from 'clsx';
import Declined from 'components/Reports/UI/Declined';

interface Props {
    appointment: Appointment;
    declined?: boolean;
    isReport?: boolean;
    isGrooming?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 16,

            [theme.breakpoints.up('md')]: {
                paddingBottom: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '40px'
            }
        },
        row: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 24,

            [theme.breakpoints.up('md')]: {
                paddingBottom: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '40px'
            }
        },
        summaryRowGrooming: {
            [theme.breakpoints.only('lg')]: {
                paddingBottom: '53px',
                marginLeft: '40px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '80px',
                marginLeft: '60px'
            }
        },
        summaryRow: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '25px'
            },
            [theme.breakpoints.only('md')]: {
                paddingBottom: '40px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingBottom: '53px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '80px'
            }
        },
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                width: '50%'
            },
            [theme.breakpoints.only('md')]: {
                width: '50%'
            }
        },
        title: {
            fontSize: 14,
            lineHeight: 1,
            color: '#A3A3A3',

            [theme.breakpoints.down('sm')]: {
                marginBottom: 8
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px'
            }
        },
        textContainer: {
            marginTop: 2,

            [theme.breakpoints.up('lg')]: {
                marginTop: '11px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '16px'
            }
        },
        bold: {
            fontWeight: 500
        },
        text: {
            fontSize: 15,
            lineHeight: 1.3,

            [theme.breakpoints.up('md')]: {
                fontSize: '16px',
                whiteSpace: 'nowrap'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '20px'
            }
        },
        icon: {
            [theme.breakpoints.down('sm')]: {
                marginRight: '6px'
            },
            [theme.breakpoints.only('md')]: {
                marginRight: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '9px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '10px'
            }
        },
        dateAndStaffContainer: {
            display: 'flex',

            [theme.breakpoints.up('md')]: {
                flexDirection: 'column'
            },
            [theme.breakpoints.only('lg')]: {
                flexDirection: 'row'
            },
            [theme.breakpoints.only('xl')]: {
                flexDirection: 'row'
            }
        }
    })
);

export const HeaderDentalReport: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const pet = (
        <Box className={classes.sectionContainer}>
            <Box>
                <Typography className={classes.title}>PETS</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={clsx(classes.text, classes.bold)}>{props.appointment.pet.name}</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={clsx(classes.text)}>{`${props.appointment.pet.type.singular} - ${
                    props.appointment.pet.breed?.name || 'Undefined'
                }`}</Typography>
            </Box>
            {props.isGrooming && props.declined && (
                <Box className={classes.textContainer}>
                    <Declined title={false} />
                </Box>
            )}
        </Box>
    );

    const petParent = (
        <Box className={classes.sectionContainer}>
            <Box>
                <Typography className={classes.title}>PET PARENT</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography
                    className={clsx(classes.text, classes.bold)}
                >{`${props.appointment.customer.firstName} ${props.appointment.customer.lastName}`}</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={clsx(classes.text)}>{props.appointment.customer.phone}</Typography>
            </Box>
        </Box>
    );

    const dateTime = (
        <Box className={classes.sectionContainer}>
            <Box>
                <Typography className={classes.title}>TIME & DATE</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={clsx(classes.text, classes.bold)}>
                    <FontAwesomeIcon
                        icon={faClock}
                        className={classes.icon}
                    />
                    {` ${props.appointment.duration.from.clone().format('hh:mm a')}`}
                </Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Typography className={clsx(classes.text)}>
                    <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className={classes.icon}
                    />
                    {` ${props.appointment.duration.from.clone().format('dddd, MMMM Do YYYY')}`}
                </Typography>
            </Box>
        </Box>
    );

    const staff = (
        <Box className={classes.sectionContainer}>
            <Box>
                <Typography className={classes.title}>STAFF</Typography>
            </Box>
            <Box className={classes.textContainer}>
                <Box className={clsx(classes.text, classes.bold)}>
                    {props.appointment.services.length > 0 && <StaffChip staff={props.appointment.services[0].staff} />}
                </Box>
            </Box>
        </Box>
    );

    const mobileRows = (
        <Fragment>
            <Box className={classes.row}>
                {pet}
                {petParent}
            </Box>
            <Box className={classes.row}>
                {dateTime}
                {staff}
            </Box>
        </Fragment>
    );

    const desktopRows = (
        <Box className={classes.row}>
            {pet}
            {petParent}
            {dateTime}
            {staff}
        </Box>
    );

    const mobileSummaryRows = (
        <>
            <Box className={classes.row}>
                {pet}
                {staff}
            </Box>

            <Box className={classes.row}>{dateTime}</Box>
        </>
    );

    const desktopSummaryRows = (
        <>
            <Box className={clsx(classes.row, classes.summaryRow)}>{pet}</Box>
            <Box className={props.isGrooming ? classes.dateAndStaffContainer : undefined}>
                <Box className={clsx(classes.row, classes.summaryRow)}>{dateTime}</Box>
                <Box
                    className={
                        props.isGrooming
                            ? clsx(classes.row, classes.summaryRowGrooming)
                            : clsx(classes.row, classes.summaryRow)
                    }
                >
                    {staff}
                </Box>
            </Box>
        </>
    );

    return (
        <Box className={classes.container}>
            {isMobile && (
                <>
                    {props.isReport && mobileRows}

                    {!props.isReport && mobileSummaryRows}
                </>
            )}

            {!isMobile && (
                <>
                    {props.isReport && desktopRows}

                    {!props.isReport && desktopSummaryRows}
                </>
            )}
        </Box>
    );
};

export default HeaderDentalReport;
